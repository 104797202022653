import React from 'react';
import { f7, f7ready, App, View, Preloader } from 'framework7-react';
import routes from '../js/routes';
import appStore from '@/stores/AppStore';
import { observer } from '@/utils/State';

@observer
export default class Application extends React.Component {
	render() {
		const f7params = {
			name: 'srvy.space', // App name
			theme: 'auto', // Automatic theme detection
			// App routes
			routes: routes,
			// Register service worker
			serviceWorker: {
				path: '/service-worker.js'
			}
		};
		return (
			<App {...f7params}>
				{appStore.showReauthLoader && (
					<div className="reauth-ctn">
						<div className="reauth loader animate__animated animate__zoomIn" key={Math.random()}>
							<i className="f7-icons">lock_shield</i>
							<h3>Authentication Check</h3>
							<Preloader color="#000" size={56}></Preloader>
						</div>
					</div>
				)}
				<View main className="safe-areas" url="/" />
			</App>
		);
	}
}
