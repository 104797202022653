import firebase from 'firebase/app';
import appStore from '@/stores/AppStore';
import _ from 'lodash';
import { f7 } from 'framework7-react';
import { showError } from '@/utils/GeneralUtils';
import API from './API';

class AuthService {
	constructor() {}
	async init() {
		console.log('AuthService INIT CALL');
		const firebaseConfig = {
			apiKey: 'AIzaSyCgO6q_laWWA551Stlguo8jHsr-LMIOJ24',
			authDomain: 'srvy-22945.firebaseapp.com',
			projectId: 'srvy-22945',
			storageBucket: 'srvy-22945.appspot.com',
			messagingSenderId: '40707902730',
			appId: '1:40707902730:web:2d832adf2cc2aef2bffef3',
			measurementId: 'G-3PDMG8T5PP'
		};
		// Initialize Firebase
		firebase.initializeApp(firebaseConfig);
		if (location.hostname === 'localhost') {
			firebase.auth().useEmulator('http://localhost:9099');
		}
		await API.init();
		appStore.showReauthLoader = true;
		//Will auto login if token is good
		firebase.auth().onAuthStateChanged((authUser) => this.handleAuthChange(authUser));
	}

	async handleSignIn(email, password) {
		appStore.showReauthLoader = true;
		//Just in case
		if (appStore.isLoggedIn) {
			this.signout();
		}
		try {
			const loginResult = await firebase.auth().signInWithEmailAndPassword(email, password);
			appStore.showReauthLoader = false;
			console.log('Login successful');
		} catch (err) {
			console.log(err);
			switch (err.code) {
				case 'auth/user-not-found': {
					f7.dialog.alert(`Unable to find user with email ${email}`, 'Authentication Error');
					break;
				}
				case 'auth/invalid-email': {
					f7.dialog.alert(`Unable to find user with email ${email}`, 'Authentication Error');
					break;
				}
				case 'auth/wrong-password': {
					f7.dialog.alert(`Invalid password for email ${email}`, 'Authentication Error');
					break;
				}
				default: {
					f7.dialog.alert(`Unkwnown error, please try again`, 'Authentication Error');
					break;
				}
			}
			appStore.showReauthLoader = false;
		}
	}

	async createAccount(email, password, name, accessCode) {
		try {
			appStore.isCreatingUser = true;
			await firebase.auth().createUserWithEmailAndPassword(email, password);
			await firebase.auth().currentUser.updateProfile({ displayName: _.trim(name) });
			await API.createAccount(accessCode, name);
			const token = await firebase.auth().currentUser.getIdToken(true);
			appStore.authToken = token;
			f7.views.main.router.navigate('/home');
			f7.views.main.router.refreshPage();
			const user = await API.getUserData();
			appStore.currentUser = user;
			appStore.isLoggedIn = true;
			appStore.showReauthLoader = false;
			appStore.isCreatingUser = false;
			return { success: true };
		} catch (err) {
			showError(err);
			return { success: false };
		}
	}

	async handleAuthChange(authUser) {
		console.log('Handling Auth Change');
		if (appStore.isCreatingUser) {
			return;
		}
		if (authUser) {
			const token = await firebase.auth().currentUser.getIdToken(true);
			appStore.authToken = token;
			f7.views.main.router.navigate('/home');
			f7.views.main.router.refreshPage();
			const user = await API.getUserData();
			appStore.currentUser = user;
			appStore.isLoggedIn = true;
			appStore.showReauthLoader = false;
		} else {
			appStore.showReauthLoader = false;
			this.signout();
		}
	}

	signout() {
		firebase.auth().signOut();
		appStore.isLoggedIn = false;
		appStore.authToken = null;
		appStore.showReauthLoader = false;
		f7.views.main.router.navigate('/');
		f7.views.main.router.refreshPage();
	}
}

export default new AuthService();
