import React from 'react';
import { Button, f7, List, ListItem, Page, Popover, Preloader, Progressbar } from 'framework7-react';
import { observable, observer } from '@/utils/State';
import AuthService from '@/services/AuthService';
import copy from 'copy-to-clipboard';
// @ts-ignore
import MBSPNG from '@/assets/mbslogo.png';
// @ts-ignore
import emptyPNG from '@/assets/empty.png';
import './home.scss';
import appStore from '@/stores/AppStore';
import API from '@/services/API';
import { autoBind, showError, validateEmail } from '@/utils/GeneralUtils';
import FormPopup from '@/components/form-popup/FormPopup';
import _ from 'lodash';
import { format } from 'date-fns';
import SurveyScreen from '@/components/survey-screen/SurveyScreen';
import ResultsPage from '@/components/results-page/ResultsPage';
import AssessmentList from '../assessments/AssessmentList';
import ReviewPage from '../review-page/ReviewPage';
import GroupResultsPage from '../group-results-page/GroupResultsPage';

const ResultsList = (props) => {
	let listMap = {};
	_.forEach(appStore.surveys, (s) => {
		if (s.groupId) {
			let arr = listMap[s.groupId];
			if (!arr) {
				arr = [];
			}
			arr.push(s);
			listMap[s.groupId] = arr;
		} else {
			listMap[s.id] = s;
		}
	});
	const visibleGroups = [];
	const results = [];
	if (!props.loading) {
		_.forEach(appStore.surveys, (i) => {
			if (visibleGroups.indexOf(i.groupId) >= 0) {
				return;
			}
			if (i && props.statusFilter.indexOf(i.status) >= 0 && i.name.toLowerCase().indexOf(props.searchQuery.toLowerCase()) >= 0) {
				const groupLength = !!i.groupId ? listMap[i.groupId].length : null;
				let reviewingCount = 0;
				let completedCount = 0;
				let openCount = 0;
				let status = _.capitalize(i.status);
				if (groupLength) {
					_.forEach(listMap[i.groupId], (gs) => {
						if (gs.status === 'reviewing') {
							reviewingCount++;
						}
						if (gs.status === 'completed') {
							completedCount++;
						}
						if (gs.status === 'open') {
							openCount++;
						}
					});
					if (openCount > 0) {
						status = 'Wating on responses';
					} else if (openCount === 0 && reviewingCount > 0) {
						status = 'Reviewing Group Surveys';
					} else if (completedCount === groupLength) {
						status = 'Completed';
					}
				}
				results.push(
					<div
						className="list-item hbox vcenter"
						key={i.id}
						onClick={() => {
							if (groupLength) {
								switch (status) {
									case 'Wating on responses': {
										f7.dialog.alert(`Group members are currently still taking this survey.`, 'Almost Finished!');
										break;
									}
									case 'Reviewing Group Surveys': {
										f7.dialog.alert('Responses are all gathers and awaiting review.', 'Almost Finished!');
										break;
									}
									case 'Completed': {
										appStore.activeResultsGroup = _.cloneDeep(listMap[i.groupId]);
										break;
									}
								}
							} else {
								if (i.status === 'open' && i.userId === appStore.currentUser.userId) {
									appStore.activeSurvey = i;
								} else if (i.status === 'open' && i.userId !== appStore.currentUser.userId) {
									f7.dialog.alert(`${i.target} is currently still taking this survey.`, 'Almost Finished!');
								} else {
									switch (i.status) {
										case 'reviewing': {
											if (_.get(appStore, 'currentUser.role', 'user') === 'admin') {
												appStore.activeResults = i;
											} else {
												f7.dialog.alert(
													'We are currently reviewing your response, upon completion the results will be reviewed with you by your administrator.',
													'Almost Finished!'
												);
											}

											break;
										}
										case 'completed': {
											if (_.get(appStore, 'currentUser.role', 'user') === 'admin') {
												appStore.activeResults = i;
											}
											break;
										}
									}
								}
							}
						}}
					>
						<div className="field">{groupLength ? i.groupName : i.target}</div>
						<div className="field">{i.name}</div>
						<div className={`field ${i.status}`}>{status}</div>
						<div className="field progress vbox vcenter hcenter">
							{!groupLength && (
								<>
									<Progressbar
										progress={Math.floor((Number(i.progress?.current || 0) / Number(i.progress?.total || 1)) * 100)}
										className="survey-progress"
									/>
									<div className="count">{`${i.progress?.current}/${i.progress?.total} Questions`}</div>
								</>
							)}
							{groupLength && (
								<>
									<Progressbar
										progress={Math.floor((Number(i.progress?.current || 0) / Number(i.progress?.total || 1)) * 100)}
										className="survey-progress"
									/>
									<div className="count">{`${reviewingCount + completedCount}/${groupLength} Responses`}</div>
								</>
							)}
						</div>
					</div>
				);
				if (groupLength) {
					visibleGroups.push(i.groupId);
				}
			}
		});
	}

	return (
		<div className="survey-list animate__animated animate__fadeInUp">
			{props.loading && (
				<div className="loading-ctn vbox vcenter hcenter">
					<Preloader color="#000" size={56}></Preloader>
				</div>
			)}
			{!props.loading && (
				<div className="hbox vcenter hright">
					<div className="search-ctn">
						<i className="f7-icons">search</i>
						<input
							className="search-input"
							placeholder="Search by survey name"
							value={props.searchQuery}
							onChange={(e) => {
								props.onSearchChange(e.target.value);
							}}
						/>
					</div>
				</div>
			)}
			{!props.loading && results.length > 0 && (
				<div className="list-header hbox vcenter">
					<div className="field">User/Group</div>
					<div className="field">Name</div>
					<div className="field">Status</div>
					<div className="field">Progress</div>
				</div>
			)}
			{!props.loading && results.length > 0 && results}
			{!props.loading && results.length === 0 && (
				<div className="empty-ctn">
					<h1>You have no available surveys</h1>
					<img src={emptyPNG} className="emptyimg"></img>
				</div>
			)}
		</div>
	);
};

const SurveyList = (props) => {
	const results = !props.loading
		? appStore.surveys
				.map((i) => {
					if (i && props.statusFilter.indexOf(i.status) >= 0 && i.name.toLowerCase().indexOf(props.searchQuery.toLowerCase()) >= 0) {
						return (
							<div
								className="list-item hbox vcenter"
								key={i.id}
								onClick={() => {
									if (i.status === 'open' && i.userId === appStore.currentUser.userId) {
										appStore.activeSurvey = i;
									} else if (i.status === 'open' && i.userId !== appStore.currentUser.userId) {
										f7.dialog.alert(`${i.target} is currently still taking this survey.`, 'Almost Finished!');
									} else {
										switch (i.status) {
											case 'reviewing': {
												if (_.get(appStore, 'currentUser.role', 'user') === 'admin') {
													appStore.activeResults = i;
												} else {
													f7.dialog.alert(
														'We are currently reviewing your response, upon completion the results will be reviewed with you by your administrator.',
														'Almost Finished!'
													);
												}

												break;
											}
											case 'completed': {
												if (_.get(appStore, 'currentUser.role', 'user') === 'admin') {
													appStore.activeResults = i;
												}
												break;
											}
										}
									}
								}}
							>
								{_.get(appStore, 'currentUser.role', 'user') === 'admin' && <div className="field">{i.target}</div>}
								<div className="field">{i.name}</div>
								<div className={`field ${i.status}`}>{_.capitalize(i.status)}</div>
								<div className="field progress vbox vcenter hcenter">
									<Progressbar
										progress={Math.floor((Number(i.progress?.current || 0) / Number(i.progress?.total || 1)) * 100)}
										className="survey-progress"
									/>
									<div className="count">{`${i.progress?.current}/${i.progress?.total} Questions`}</div>
								</div>
							</div>
						);
					}
					return null;
				})
				.filter((r) => r !== null)
		: [];
	return (
		<div className="survey-list animate__animated animate__fadeInUp">
			{props.loading && (
				<div className="loading-ctn vbox vcenter hcenter">
					<Preloader color="#000" size={56}></Preloader>
				</div>
			)}
			{!props.loading && (
				<div className="hbox vcenter hright">
					<div className="search-ctn">
						<i className="f7-icons">search</i>
						<input
							className="search-input"
							placeholder="Search by survey name"
							value={props.searchQuery}
							onChange={(e) => {
								props.onSearchChange(e.target.value);
							}}
						/>
					</div>
				</div>
			)}
			{!props.loading && results.length > 0 && (
				<div className="list-header hbox vcenter">
					{_.get(appStore, 'currentUser.role', 'user') === 'admin' && <div className="field">User</div>}
					<div className="field">Name</div>
					<div className="field">Status</div>
					<div className="field">Progress</div>
				</div>
			)}
			{!props.loading && results.length > 0 && results}
			{!props.loading && results.length === 0 && (
				<div className="empty-ctn">
					<h1>You have no available surveys</h1>
					<img src={emptyPNG} className="emptyimg"></img>
				</div>
			)}
		</div>
	);
};

const InvitesList = (props) => {
	return (
		<div className="invite-list">
			<div className="list-header hbox vcenter">
				<div className="field">To</div>
				<div className="field">From</div>
				<div className="field">Date</div>
				<div className="field">Status</div>
				<div className="field">Assessments</div>
				<div className="btn-spacer"></div>
			</div>
			{props.loading && (
				<div className="loading-ctn vbox vcenter hcenter">
					<Preloader color="#000" size={56}></Preloader>
				</div>
			)}
			{!props.loading &&
				appStore.invites.map((i) => {
					return (
						<div className="list-item hbox vcenter" key={i.id}>
							<div className="field ellipse">{i.email}</div>
							<div className="field ellipse">{i.createdBy || 'Unknown'}</div>
							<div className="field">{format(new Date(Number(i.createdDate)), 'MMM dd, yyyy')}</div>
							<div className={`field ${i.status}`}>{_.capitalize(i.status)}</div>
							<div className="field assessments">{i.surveys.join(`\n`)}</div>
							{i.status === 'pending' && (
								<Button
									small
									className="list-btn"
									onClick={() => {
										const url = `${location.href}?accesscode=${i.id}`;
										copy(url);
										f7.toast
											.create({
												text: 'Link copied to clipboard!',
												position: 'top',
												horizontalPosition: 'right',
												closeTimeout: 1000,
												icon: '<i class="f7-icons">checkmark_circle</i>'
											})
											.open();
									}}
								>
									<i className="f7-icons">link</i> Copy Link
								</Button>
							)}
							{i.status === 'accepted' && <div className="spacer"></div>}
						</div>
					);
				})}
		</div>
	);
};

@observer
export default class HomePage extends React.Component {
	constructor(props) {
		super(props);
		this.data = observable({
			surveySearch: '',
			loadingInvites: false,
			loadingSurveys: false,
			inviteOpen: false,
			groupInviteOpen: false,
			groupFormData: {
				assessmentIds: [],
				userIds: []
			},
			inviteFormData: {
				assessmentIds: [],
				existingUser: false,
				isAdmin: false,
				email: '',
				userId: 'select'
			}
		});
		autoBind(this);
	}

	componentDidMount() {
		this.loadSurveys();
		if (appStore.currentUser?.role === 'admin') {
			this.loadInvites();
		}
		setTimeout(() => {
			if (appStore.surveys.length === 0) {
				this.loadSurveys();
			}
		}, 1000);
	}

	async loadSurveys() {
		this.data.loadingSurveys = true;
		const surveys = await API.getSurveys();
		if (surveys) {
			appStore.surveys = surveys;
		}
		this.data.loadingSurveys = false;
	}

	async loadInvites() {
		this.data.loadingInvites = true;
		const invites = await API.getInvites();
		if (invites) {
			appStore.invites = invites;
		}
		this.data.loadingInvites = false;
	}

	onSendGroupInvite() {
		let { assessmentIds, userIds } = this.data.groupFormData;
		if (assessmentIds.length === 0 || userIds.length === 0) {
			showError({ message: 'You must select at least one assessment and user' });
			return;
		}
		f7.dialog.preloader();
		API.sendGroupInvite(this.data.groupFormData)
			.then(() => {
				this.loadInvites();
				f7.dialog.close();
				f7.dialog.alert('Selected user will now see the survey in their list upon next login or after clicking refresh.', 'Success');
				this.data.groupInviteOpen = false;
				this.data.groupFormData = {
					assessmentIds: [],
					userIds: []
				};
			})
			.catch((err) => {
				showError(err);
			});
	}

	onSendInvite() {
		let { assessmentIds, existingUser, email, userId } = this.data.inviteFormData;
		if (assessmentIds.length === 0) {
			showError({ message: 'You must select at least one assessment' });
			return;
		}
		if (!existingUser && !validateEmail(email)) {
			showError({ message: 'Invalid email address' });
			return;
		}
		if (existingUser && userId === 'select') {
			showError({ message: 'Please select an existing user' });
			return;
		}
		f7.dialog.preloader();
		API.createSurveyInvite(this.data.inviteFormData)
			.then((res) => {
				this.loadInvites();
				f7.dialog.close();
				f7.dialog.alert(
					'If this is a new user the invite link has been copied to your clipboard. If they are existing they will now see the survey in their list upon next login.',
					'Success'
				);
				if (!existingUser) {
					const url = `${location.href}?accesscode=${res.id}`;
					copy(url);
					f7.toast
						.create({
							text: 'Link copied to clipboard!',
							position: 'top',
							horizontalPosition: 'right',
							closeTimeout: 2000,
							icon: '<i class="f7-icons">checkmark_circle</i>'
						})
						.open();
				}
				this.data.inviteOpen = false;
			})
			.catch((err) => {
				showError(err);
			});
	}

	onInviteClick(type) {
		f7.dialog.preloader();
		API.getAssessments()
			.then((res) => {
				appStore.assessments = res;
				if (type === 'group') {
					this.data.groupInviteOpen = true;
				} else {
					this.data.inviteOpen = true;
				}
				f7.dialog.close();
			})
			.catch((err) => {
				showError(err);
			});

		API.getExistingUsers()
			.then((res) => {
				appStore.users = res;
			})
			.catch((err) => {
				showError(err);
			});
	}

	getGroupFormConfig() {
		const assessmentListItems = {};
		const userListItems = {};
		_.forEach(appStore.assessments, (a) => {
			assessmentListItems[a.id] = a.name;
		});
		_.forEach(appStore.users, (u) => {
			userListItems[u.userId] = u.name;
		});
		const config = {
			name: {
				label: 'Group Survey Name',
				placeholder: 'Name for this group survey',
				type: 'text',
				validator: {
					type: 'length',
					value: 2
				}
			},
			assessmentIds: {
				label: 'Create surveys from available assessments: (You can select multiple)',
				type: 'multiselect',
				className: 'multiselect-form-input',
				listItems: assessmentListItems
			},
			userIds: {
				label: 'Select from existing users',
				type: 'multiselect',
				className: 'multiselect-form-input',
				listItems: userListItems
			}
		};
		return config;
	}

	getInviteFormConfig() {
		const assessmentListItems = {};
		const userListItems = { select: 'Select a User' };
		_.forEach(appStore.assessments, (a) => {
			assessmentListItems[a.id] = a.name;
		});
		_.forEach(appStore.users, (u) => {
			userListItems[u.userId] = u.name;
		});
		const config = {
			assessmentIds: {
				label: 'Create surveys from available assessments: (You can select multiple)',
				type: 'multiselect',
				className: 'multiselect-form-input',
				listItems: assessmentListItems
			},

			existingUser: {
				label: 'Are They An Existing User?',
				type: 'toggle'
			}
		};
		if (!this.data.inviteFormData.existingUser) {
			config.email = {
				label: 'Invitee Email',
				placeholder: 'Input email',
				type: 'email',
				validator: {
					type: 'email'
				}
			};
			config.isAdmin = {
				label: 'Add to organization: (Can assign surveys, see results, and build assessments)',
				type: 'toggle'
			};
		} else {
			config.userId = {
				label: 'Select from existing users',
				type: 'dropdown',
				className: 'multiselect-form-input',
				listItems: userListItems
			};
		}
		return config;
	}

	getActivePage() {
		switch (appStore.activePage) {
			case 'surveys': {
				return (
					<div className="main-content survey-page">
						<div className="page-header hbox vcenter animate__animated animate__fadeIn">
							<h1>Open Surveys</h1>
							<div className="grow-1"></div>
							<Button small className="header-btn" onClick={this.loadSurveys}>
								<i className="f7-icons">arrow_2_circlepath</i> Refresh
							</Button>
							{appStore.isAdmin() && (
								<Button small fill className="header-btn m-l-24 " onClick={this.onInviteClick}>
									<i className="f7-icons">plus</i> Survey
								</Button>
							)}
							{appStore.isAdmin() && (
								<Button
									small
									fill
									className="header-btn m-l-24 "
									onClick={() => {
										this.onInviteClick('group');
									}}
								>
									<i className="f7-icons">plus</i> Group Survey
								</Button>
							)}
						</div>
						<SurveyList
							statusFilter={['open']}
							loading={this.data.loadingSurveys}
							searchQuery={this.data.surveySearch}
							onSearchChange={(val) => {
								this.data.surveySearch = val;
							}}
						></SurveyList>
					</div>
				);
			}
			case 'results': {
				return (
					<div className="main-content results-page">
						<div className="page-header hbox vcenter animate__animated animate__fadeIn">
							<h1>All Results</h1>
							<div className="grow-1"></div>
							<Button small className="header-btn" onClick={this.loadSurveys}>
								<i className="f7-icons">arrow_2_circlepath</i> Refresh
							</Button>
						</div>
						{appStore.isAdmin() ? (
							<ResultsList
								statusFilter={['reviewing', 'completed']}
								loading={this.data.loadingSurveys}
								searchQuery={this.data.surveySearch}
								onSearchChange={(val) => {
									this.data.surveySearch = val;
								}}
							></ResultsList>
						) : (
							<SurveyList
								statusFilter={['reviewing', 'completed']}
								loading={this.data.loadingSurveys}
								searchQuery={this.data.surveySearch}
								onSearchChange={(val) => {
									this.data.surveySearch = val;
								}}
							></SurveyList>
						)}
					</div>
				);
			}
			case 'invites': {
				return (
					<div className="main-content invites-page">
						<div className="page-header hbox vcenter animate__animated animate__fadeIn">
							<h1>All Invites</h1>
							<div className="grow-1"></div>
							<Button small className="header-btn" onClick={this.loadInvites}>
								<i className="f7-icons">arrow_2_circlepath</i> Refresh
							</Button>
							{appStore.isAdmin() && (
								<Button small fill className="header-btn m-l-24" onClick={this.onInviteClick}>
									<i className="f7-icons">plus</i> Invite
								</Button>
							)}
						</div>
						<InvitesList loading={this.data.loadingInvites}></InvitesList>
					</div>
				);
			}
			case 'assessments': {
				return <AssessmentList></AssessmentList>;
			}
			case 'review': {
				return <ReviewPage></ReviewPage>;
			}
			case 'profile': {
				return (
					<div className="main-content profile-page">
						<div className="page-header hbox vcenter animate__animated animate__fadeIn">
							<h1>Profile</h1>
							<div className="grow-1"></div>
						</div>
						<div className="profile-content">
							<h2>{appStore.currentUser.name}</h2>
							<h3>{appStore.currentUser.email}</h3>
							<h3>Role: {_.capitalize(appStore.currentUser.role)}</h3>
						</div>
					</div>
				);
			}
			default: {
				return (
					<div className="main-content invites-page">
						<div className="page-header hbox vcenter animate__animated animate__fadeIn">
							<h1>Coming Soon</h1>
							<div className="grow-1"></div>
						</div>
					</div>
				);
			}
		}
	}

	render() {
		return (
			<Page name="home" className="home-page">
				<div className="header-bar hbox vcenter">
					<div className="brand-ctn">
						<img src={MBSPNG} />
						<div className="poweredby">
							Powered By: <span>srvy.space</span>
						</div>
					</div>
					<div className="grow-1"></div>
					<div
						className={`navlink ${appStore.activePage === 'surveys' ? 'active' : ''}`}
						onClick={() => {
							appStore.activePage = 'surveys';
							this.loadSurveys();
						}}
					>
						Surveys
					</div>
					<div
						className={`navlink ${appStore.activePage === 'results' ? 'active' : ''}`}
						onClick={() => {
							appStore.activePage = 'results';
							this.loadSurveys();
						}}
					>
						Results
					</div>
					{appStore.isAdmin() && (
						<div
							className={`navlink mr8 ${appStore.activePage === 'assessments' ? 'active' : ''}`}
							onClick={() => {
								appStore.activePage = 'assessments';
							}}
						>
							Assessments
						</div>
					)}
					{appStore.isAdmin() && (
						<div
							className={`navlink ${appStore.activePage === 'review' ? 'active' : ''}`}
							onClick={() => {
								appStore.activePage = 'review';
							}}
						>
							Review
						</div>
					)}
					<div className="grow-1"></div>
					<Button small className="header-btn" popoverOpen=".account-menu">
						<i className="f7-icons">person</i>
						Manage
					</Button>
				</div>
				{this.getActivePage()}
				<FormPopup
					opened={this.data.groupInviteOpen}
					title="Group Survey Invite"
					buttonText="Invite"
					formData={this.data.groupFormData}
					formConfig={this.getGroupFormConfig()}
					onSubmit={this.onSendGroupInvite}
					onClose={() => {
						console.log('Closing Group Form');
						this.data.groupInviteOpen = false;
						this.data.groupFormData = {
							assessmentIds: [],
							userIds: []
						};
					}}
					subtitle={
						<div className="subtitle">
							Invite multiple existing users to participate in the same survey. Afterwards scoring will be combined under reports and
							given via total and individual metrics.
						</div>
					}
				/>
				<FormPopup
					opened={this.data.inviteOpen}
					title="Survey Invite"
					buttonText="Invite"
					formData={this.data.inviteFormData}
					formConfig={this.getInviteFormConfig()}
					onSubmit={this.onSendInvite}
					onClose={() => {
						this.data.inviteOpen = false;
						this.data.inviteFormData = {
							assessmentIds: [],
							existingUser: false,
							email: '',
							userId: 'select',
							isAdmin: false
						};
					}}
					subtitle={
						<div className="subtitle">
							Sending an invite creates a survey for this user to take. If they don't already have an account they can create one with
							their invite code. If they are an existing user the survey will show up in their list of open surveys.
						</div>
					}
				/>
				{appStore.activeSurvey && <SurveyScreen onClose={this.loadSurveys}></SurveyScreen>}
				{appStore.activeResults && <ResultsPage></ResultsPage>}
				{appStore.activeResultsGroup && appStore.activeResultsGroup.length > 0 && <GroupResultsPage></GroupResultsPage>}
				<Popover className="account-menu">
					<List>
						{appStore.isAdmin() && (
							<ListItem
								link="#"
								popoverClose
								title="Users / Invites"
								onClick={() => {
									appStore.activePage = 'invites';
									this.loadInvites();
								}}
							/>
						)}
						<ListItem
							link="#"
							popoverClose
							title="Profile"
							onClick={() => {
								appStore.activePage = 'profile';
							}}
						/>
						<ListItem link="#" popoverClose title="Sign Out" onClick={AuthService.signout} />
					</List>
				</Popover>
			</Page>
		);
	}
}
