import API from '@/services/API';
import appStore from '@/stores/AppStore';
import { Button, List, ListItem, Preloader, Progressbar } from 'framework7-react';
import React, { Component } from 'react';
import { autoBind } from '@/utils/GeneralUtils';
import { observer, observable } from '@/utils/State';
import _ from 'lodash';
import './group-results-page.scss';
import BasicInput from '@/components/basic-input/BasicInput';
import { runInAction } from 'mobx';

@observer
export default class GroupResultsPage extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			loading: false,
			assessment: null,
			totalResults: null,
			activeResults: null,
			currentSurvey: null
		});
		autoBind(this);
	}

	async componentDidMount() {
		this.data.loading = true;
		const assessment = await API.getSurveyAssesment(_.get(appStore, 'activeResultsGroup[0].id'));
		if (assessment) {
			this.data.assessment = assessment;
			let total = 0;
			let name = null;
			_.forEach(appStore.activeResultsGroup, (s) => {
				console.log(JSON.stringify(s, null, 2));
				total += s.score;
				if (!name) {
					name = s.groupName;
				}
			});
			this.data.totalResults = { score: Math.floor(total / appStore.activeResultsGroup.length), name };
		} else {
			appStore.activeResultsGroup = [];
		}
		this.data.loading = false;
	}

	getActivePage() {
		let { assessment, loading, activeResults, totalResults } = this.data;
		if (loading || !assessment || !totalResults) {
			return (
				<div className="loading-ctn vbox vcenter hcenter">
					<Preloader color="#000" size={56}></Preloader>
				</div>
			);
		}
		let content;
		if (totalResults && !activeResults) {
			//SHOW total results
			content = (
				<div className="total-scores">
					<h3>Individual Scores:</h3>
					{appStore.activeResultsGroup.map((r) => {
						return (
							<div className="score-row hbox vcenter" key={`score-${r.id}`}>
								<div className="name grow-1">{r.target}</div>
								<div className="score">{r.score}</div>
							</div>
						);
					})}
					<h1>Total Score: {totalResults.score}/100</h1>
				</div>
			);
		} else {
			content = (assessment &&
				Object.keys(assessment.questions).map((a) => {
					let q = assessment.questions[a];
					let { results } = activeResults;
					const formatAnswer = (answer) => {
						switch (q.type) {
							case 'multiselect': {
								return answer.map((a) => {
									return `${q.listItems[a]?.name} \n`;
								});
							}
							case 'radiogroup': {
								return q.listItems[answer]?.name;
							}
							default: {
								return answer;
							}
						}
					};
					return (
						<div className="question" key={`question-answer-${a}`}>
							<div className="label">{`${Number(a) + 1}. ${q.label}`}</div>
							{q.subtext && <div className="subtext">{q.subtext}</div>}
							{q.key && results[q.key] && <div className="answer">{formatAnswer(results[q.key])}</div>}
						</div>
					);
				})) || <div>Unknown Error</div>;
		}
		//====================DYNAMIC SURVEY RESULTS BUILDER =============================
		return (
			<div className={`results y-scroll`} key={`survey-results`}>
				{content}
				{activeResults && activeResults?.status === 'completed' && !_.isEmpty(activeResults?.comment) && (
					<>
						<h4>Reviewer Comment:</h4>
						<div className="comments">{activeResults.comment}</div>
					</>
				)}
			</div>
		);
	}

	render() {
		let { totalResults, activeResults, currentSurvey } = this.data;
		let { activeResultsGroup } = appStore;

		return (
			<div className="group-results-page">
				<div className="active-page animate__animated animate__zoomIn">
					<div className="page-header hbox vcenter hright">
						<h1 className="grow-1">{activeResults ? activeResults?.target : totalResults?.name} Results</h1>
						<Button
							small
							onClick={() => {
								if (this.props.onClose) {
									this.props.onClose();
								}
								appStore.activeResultsGroup = [];
							}}
						>
							Close
						</Button>
					</div>
					<div className="step-ctn">{this.getActivePage()}</div>
					<div className="btn-ctn hbox vcenter">
						<h3 className="grow-1 scoring">
							{activeResults ? `Individual Score: ${activeResults?.score || 0}/100` : `Total Score: ${totalResults?.score || 0}/100`}
						</h3>
						{(currentSurvey || currentSurvey === 0) && (
							<Button
								outline
								large
								className="round-btn"
								onClick={() => {
									if (currentSurvey !== 0) {
										this.data.currentSurvey--;
										this.data.activeResults = _.cloneDeep(appStore.activeResultsGroup[this.data.currentSurvey]);
									} else {
										this.data.currentSurvey = null;
										this.data.activeResults = null;
									}
								}}
							>
								Back
							</Button>
						)}
						{(currentSurvey === null || currentSurvey < activeResultsGroup.length - 1) && (
							<Button
								fill
								large
								className="round-btn"
								onClick={() => {
									if (currentSurvey === null) {
										this.data.currentSurvey = 0;
										this.data.activeResults = _.cloneDeep(activeResultsGroup[this.data.currentSurvey]);
									} else {
										this.data.currentSurvey++;
										this.data.activeResults = _.cloneDeep(activeResultsGroup[this.data.currentSurvey]);
									}
								}}
							>
								Next
							</Button>
						)}
					</div>
				</div>
			</div>
		);
	}
}
