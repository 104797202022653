import React, { Component } from 'react';
import _ from 'lodash';
import { observer, observable } from '@/utils/State';
import { autoBind, validateEmail, validateUrl } from '@/utils/GeneralUtils';
import BasicInput from '@/components/basic-input/BasicInput';
import { List, ListItem, Preloader, Toggle } from 'framework7-react';
import DropDown from '@/components/drop-down/DropDown';
import './form-builder.scss';

@observer
export default class FormBuilder extends Component {
	constructor(props) {
		super(props);
		this.data = observable({ emailValid: false });
		autoBind(this);
	}

	componentDidMount() {
		this.runValidator();
	}

	validateField(validator, value) {
		switch (validator.type) {
			case 'length': {
				if (value && value.length >= validator.value) {
					return true;
				}
				return false;
			}
			case 'date': {
				if (value && value.length === 10) {
					return true;
				}
				return false;
			}
			case 'phone': {
				value = value.replace(/\D/g, '');
				if (value && value.length === 10) {
					return true;
				}
				return false;
			}
			case 'email': {
				return this.data.emailValid || validateEmail(value);
			}
			case 'url': {
				return validateUrl(value);
			}
			case 'min': {
				return value < validator.value;
			}
		}
	}

	runValidator() {
		let { formData, formConfig, customValidator } = this.props;
		if (!customValidator) {
			let isValid = true;
			_.forEach(Object.keys(formConfig), (key) => {
				if (key.indexOf('row') == 0) {
					_.forEach(Object.keys(_.get(this, `props.formConfig[${key}]`, {})), (rowKey) => {
						//row fields validator
						let validator = _.get(formConfig, `${key}.${rowKey}.validator`);
						if (validator) {
							let valid = this.validateField(validator, formData[rowKey]);
							if (!valid) {
								isValid = false;
							}
						}
					});
				} else {
					//root field validator
					let validator = _.get(formConfig, `${key}.validator`);
					if (validator) {
						let valid = this.validateField(validator, formData[key]);
						if (!valid) {
							isValid = false;
						}
					}
				}
			});
			if (this.props.setValidationState) {
				this.props.setValidationState(isValid);
			}
		}
	}

	onInputChange(evt) {
		let target = evt.currentTarget;
		let value = target.value;
		if (target.name === 'phone') {
			value = value.replace(/\D/g, '');
		}
		let dataProperty = _.clone(target.name);
		if (dataProperty.indexOf('.') >= 0) {
			dataProperty = dataProperty.split('.')[dataProperty.split('.').length - 1];
		}
		this.props.formData[dataProperty] = value;

		if (this.props.afterChange) {
			this.props.afterChange(dataProperty);
		}
		this.runValidator();
	}

	onDateInputChange(evt) {
		let target = evt.currentTarget;
		const value = target.value;
		const cleanValue = value.replace(/[^0-9]/g, '');
		const cleanParts = cleanValue.split('');
		let month = cleanParts.slice(0, 2).join('');
		let day = cleanParts.slice(2, 4).join('');
		let year = cleanParts.slice(4, 8).join('');
		let dataProperty = target.name;
		if (target.name.indexOf('.') >= 0) {
			dataProperty = target.name.split('.')[target.name.split('.').length - 1];
		}
		if (cleanParts.length >= 5) {
			this.props.formData[dataProperty] = `${month}/${day}/${year}`;
		} else if (cleanParts.length >= 3) {
			this.props.formData[dataProperty] = `${month}/${day}`;
		} else {
			this.props.formData[dataProperty] = `${month}`;
		}
		if (this.props.afterChange) {
			this.props.afterChange(dataProperty);
		}
		this.runValidator();
	}

	handleEmailCheck(isValid) {
		const email = this.props.formData.email || '';
		this.props.formData.emailValid = isValid && email.length > 0;
	}

	buildMultiSelect(key, config) {
		return (
			<div className={`multiselect-form-selector ${config.calssName || ''}`} key={key}>
				<div className="label">{config.label}</div>
				<List noHairlines>
					{Object.keys(config.listItems).map((k) => {
						return (
							<ListItem
								checkbox
								title={config.listItems[k]}
								name={`${key}-checkbox`}
								key={k}
								value={k}
								onChange={() => {
									let { formData } = this.props;
									if (formData[key].indexOf(k) < 0) {
										formData[key].push(k);
									} else {
										const index = formData[key].indexOf(k);
										if (index > -1) {
											formData[key].splice(index, 1);
										}
									}
								}}
							/>
						);
					})}
				</List>
			</div>
		);
	}

	buildDropDown(key, config) {
		let { formData } = this.props;
		let dataProperty = key;
		if (dataProperty.indexOf('.') >= 0) {
			dataProperty = dataProperty.split('.')[dataProperty.split('.').length - 1];
		}
		let selected = formData[dataProperty];

		let listItems = [];
		_.forEach(Object.keys(config.listItems), (listItemKey) => {
			if (key) {
				let label = config.listItems[listItemKey];
				let value = _.clone(label);
				if (config.displayValues && typeof config.displayValues === 'function') {
					value = config.displayValues(listItemKey, config.listItems);
				}
				listItems.push(
					<ListItem
						link="#"
						popoverClose
						className="drop-down-list-item"
						key={`${dataProperty}-${listItemKey}-dropdown-item`}
						onClick={() => {
							this.props.formData[dataProperty] = listItemKey;
							if (this.props.afterChange) {
								this.props.afterChange(dataProperty);
							}
							this.runValidator();
						}}
					>
						<div className="dd-label">{value}</div>
					</ListItem>
				);
			}
		});
		let displayValue = config.listItems[selected];
		if (config.displayValues && typeof config.displayValues === 'function') {
			displayValue = config.displayValues(selected, config.listItems);
		}
		return (
			<DropDown
				key={`srvy-dropdown-${dataProperty}`}
				name={`formbuilder-dropdown-${dataProperty}`}
				label={config.label}
				icon={config.icon}
				items={listItems}
				className={`srvy-dropdown md ${config.className}`}
				displayValue={displayValue}
			/>
		);
	}

	buildField(key) {
		let { formData } = this.props;
		let { label, placeholder, type, className, validator, disabled, resizable } = _.get(this.props, `formConfig.${key}`);
		let dataProperty = key;
		if (dataProperty.indexOf('.') >= 0) {
			dataProperty = dataProperty.split('.')[dataProperty.split('.').length - 1];
		}
		switch (type) {
			case 'loader': {
				return (
					<div className="form-loader vbox vcenter hcenter" key="srvy-form-loader-key">
						<Preloader></Preloader>
					</div>
				);
			}
			case 'text': {
				return (
					<BasicInput
						key={`srvy-form-input-${key}`}
						validate
						label={label}
						name={key}
						type={type}
						placeholder={placeholder}
						value={formData[dataProperty]}
						onChange={this.onInputChange}
						disabled={disabled || false}
						className={`${className || ''}`}
					/>
				);
			}
			case 'textarea': {
				return (
					<BasicInput
						key={`srvy-form-input-${key}`}
						validate
						label={label}
						name={key}
						type={type}
						placeholder={placeholder}
						value={formData[dataProperty]}
						onChange={this.onInputChange}
						disabled={disabled || false}
						className={`${className || ''} ${resizable ? 'resizable' : ''}`}
					/>
				);
			}
			case 'number': {
				return (
					<BasicInput
						key={`srvy-form-input-${key}`}
						label={label}
						name={key}
						type={type}
						placeholder={placeholder}
						value={formData[dataProperty]}
						onChange={this.onInputChange}
						disabled={disabled || false}
						className={`${className || ''}`}
					/>
				);
			}
			case 'phone': {
				return (
					<BasicInput
						key={`srvy-form-input-${key}`}
						label={label}
						name={key}
						type="text"
						maxlength={10}
						minlength={10}
						validate={true}
						placeholder={placeholder}
						value={formData[dataProperty]}
						onChange={this.onInputChange}
						disabled={disabled || false}
						className={`${className || ''}`}
					/>
				);
			}
			case 'email': {
				return (
					<BasicInput
						key={`srvy-form-input-${key}`}
						label={label}
						validate={true}
						name={key}
						type={type}
						placeholder={placeholder}
						value={formData[dataProperty]}
						disabled={disabled || false}
						onValidate={
							validator
								? (isValid) => {
										this.data.emailValid = isValid;
								  }
								: this.handleEmailCheck
						}
						onChange={this.onInputChange}
						className={`form-builder-email-input ${className || ''}`}
					/>
				);
			}
			case 'password': {
				return (
					<BasicInput
						key={`srvy-form-input-${key}`}
						label={label}
						name={key}
						type={type}
						placeholder={placeholder}
						value={formData[dataProperty]}
						onChange={this.onInputChange}
						disabled={disabled || false}
						className={`${className || ''}`}
					/>
				);
			}
			case 'dropdown': {
				return this.buildDropDown(key, _.get(this, `props.formConfig.${key}`));
			}
			case 'multiselect': {
				return this.buildMultiSelect(key, _.get(this, `props.formConfig.${key}`));
			}
			case 'toggle': {
				let config = _.get(this, `props.formConfig.${key}`);
				return (
					<div className="srvy-form-toggle hbox vcenter" key={key}>
						<div className="label">{config.label}</div>
						<Toggle
							checked={formData[key]}
							onChange={(e) => {
								formData[key] = e.target.checked;
							}}
						></Toggle>
					</div>
				);
			}
			case 'date': {
				return (
					<BasicInput
						key={`srvy-form-input-${key}`}
						label={label}
						onChange={this.onDateInputChange}
						name={key}
						placeholder="MM/DD/YYYY"
						value={formData[dataProperty]}
						disabled={disabled || false}
						type="text"
						className={`${className || ''}`}
					/>
				);
			}
			default: {
				return;
			}
		}
	}

	formBuilder() {
		let formInputs = [];
		_.forEach(Object.keys(this.props.formConfig), (key) => {
			if (key.indexOf('row') == 0) {
				let rowFields = [];
				_.forEach(Object.keys(_.get(this, `props.formConfig[${key}]`, {})), (rowKey) => {
					let field = this.buildField(`${key}.${rowKey}`);
					if (field) {
						rowFields.push(field);
					}
				});
				formInputs.push(
					<div className="form-row hbox vtop" key={`form-row-${key}`}>
						{rowFields}
					</div>
				);
			} else {
				let field = this.buildField(key);
				if (field) {
					formInputs.push(field);
				}
			}
		});
		return formInputs;
	}

	render() {
		return <div className="srvy-form-builder">{this.formBuilder()}</div>;
	}
}
