import { showError } from '@/utils/GeneralUtils';
import firebase from 'firebase/app';
import { f7 } from 'framework7-react';
class API {
	async init() {
		return new Promise((resolve) => {
			if (location.hostname === 'localhost') {
				firebase.functions().useEmulator('localhost', 5001);
			}
			resolve(true);
		});
	}

	async callFunction(name, data) {
		const fn = firebase.functions().httpsCallable(name);
		try {
			const response = await fn(data);
			return response.data;
		} catch (err) {
			showError(err);
			return null;
		}
	}

	async callAPIFunction(name, data) {
		const fn = firebase.functions().httpsCallable('srvyAPIFunction');
		try {
			const response = await fn({ functionName: name, payload: data });
			return response.data;
		} catch (err) {
			showError(err);
			return null;
		}
	}

	async getUserData() {
		return await this.callAPIFunction('getUserData');
	}

	async getInviteSignupData(id) {
		if (!id) {
			return;
		}
		return await this.callFunction('getInviteSignupData', { inviteId: id });
	}

	async createAccount(accessCode, name) {
		return await this.callAPIFunction('createAccount', { accessCode: accessCode, name: name });
	}

	async getSurveys() {
		let surveys = await this.callAPIFunction('getSurveys');
		return surveys.sort((a, b) => {
			return b.createdDate - a.createdDate;
		});
	}

	async getInvites() {
		return await this.callAPIFunction('getInvites');
	}

	async getAssessments() {
		return await this.callAPIFunction('getAssessments');
	}

	async assessmentHasSurvey(assessmentId) {
		return await this.callAPIFunction('assessmentHasSurvey', { assessmentId: assessmentId });
	}

	async saveAssessment(assessment) {
		return await this.callAPIFunction('saveAssessment', assessment);
	}

	async deleteAssessment(assessmentId) {
		return await this.callAPIFunction('deleteAssessment', { assessmentId: assessmentId });
	}

	async getExistingUsers() {
		return await this.callAPIFunction('getExistingUsers');
	}

	async createSurveyInvite(formData) {
		return await this.callAPIFunction('createSurveyInvite', formData);
	}

	async sendGroupInvite(formData) {
		return await this.callAPIFunction('sendGroupInvite', formData);
	}

	async getSurveyAssesment(id) {
		return await this.callAPIFunction('getSurveyAssesment', { surveyId: id });
	}

	async updateSurveyResults(survey) {
		return await this.callAPIFunction('updateSurvey', survey);
	}

	async completeSurvey(survey) {
		return await this.callAPIFunction('completeSurvey', survey);
	}

	async saveReview(data) {
		return await this.callAPIFunction('saveReview', data);
	}
}

export default new API();
