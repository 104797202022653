import React from 'react';
import { Button, f7, Preloader, Stepper } from 'framework7-react';
import { observable, observer } from '@/utils/State';
// @ts-ignore
import emptyPNG from '@/assets/empty.png';
import appStore from '@/stores/AppStore';
import API from '@/services/API';
import { autoBind, showError } from '@/utils/GeneralUtils';
import _ from 'lodash';
import './review-page.scss';
import BasicInput from '@/components/basic-input/BasicInput';

@observer
export default class ReviewPage extends React.Component {
	constructor(props) {
		super(props);
		this.data = observable({
			loading: false,
			searchQuery: '',
			activeReview: null,
			scoringData: {},
			comment: ''
		});
		autoBind(this);
	}

	componentDidMount() {
		this.loadData();
	}

	async loadData() {
		f7.dialog.preloader();
		Promise.all([API.getAssessments(), API.getSurveys()])
			.then((response) => {
				const assessments = response[0];
				const surveys = response[1];
				if (assessments && assessments.length > 1) {
					appStore.assessments = assessments;
				}
				if (surveys && surveys.length > 1) {
					appStore.surveys = surveys;
				}
				f7.dialog.close();
			})
			.catch((err) => {
				console.log(err);
				f7.dialog.close();
			});
	}

	getTotalPoints() {
		let { questions } = _.get(appStore, `assessments[${this.data.activeReview?.assessmentId}]`, {});
		let total = 0;
		_.forEach(questions, (q) => {
			total += this.getQuestionPoints(q);
		});
		return total;
	}

	getQuestionPoints(q) {
		if (['text', 'textarea', 'number'].indexOf(q.type) >= 0) {
			return q.points;
		} else if (q.type === 'multiselect') {
			let total = 0;
			_.forEach(Object.values(q.listItems), (i) => {
				if (i.points >= 0) {
					total += i.points;
				}
			});
			return total;
		} else if (q.type === 'radiogroup') {
			let max = 0;
			_.forEach(Object.values(q.listItems), (i) => {
				if (i.points >= max) {
					max = i.points;
				}
			});
			return max;
		}
	}

	getScore(question, answer) {
		if (['text', 'textarea', 'number'].indexOf(question.type) >= 0) {
			return null;
		} else if (question.type === 'multiselect') {
			let total = 0;
			_.forEach(Object.keys(question.listItems), (i) => {
				if (answer.indexOf(i)) {
					total += Number(question.listItems[i].points);
				}
			});
			return total;
		} else if (question.type === 'radiogroup') {
			return _.get(question, `listItems[${answer}].points`, 0);
		}
		return null;
	}

	onCommentChange(e) {
		this.data.comment = e.target.value;
	}

	setScoringData() {
		let { activeReview } = this.data;
		let { assessments } = appStore;
		let assessment = _.find(assessments, { id: activeReview.assessmentId });
		let scores = {};
		_.forEach(assessment.questions, (q) => {
			let { results } = activeReview;
			let score = this.getScore(q, results[q.key]);
			if (score) {
				scores[q.key] = score;
			} else {
				scores[q.key] = 0;
			}
		});
		this.data.scoringData = scores;
	}

	getWeightedScore() {
		let { activeReview, scoringData } = this.data;
		let { assessments } = appStore;
		let assessment = _.find(assessments, { id: activeReview.assessmentId });
		let total = 0;
		let current = 0;
		let weightedScore = 0;
		let index = 0;
		Object.keys(assessment.questions).map((a) => {
			let q = assessment.questions[a];
			let { results } = activeReview;
			let score = this.getScore(q, results[q.key]);
			let maxPoints = this.getQuestionPoints(q);
			total += maxPoints;
			current += score ? score : scoringData[q.key];
			weightedScore += Math.floor((score ? score / maxPoints : scoringData[q.key] / maxPoints) * Number(assessment.scoring[index]));
			index++;
		});
		return weightedScore;
	}

	getReviewDetails() {
		let { activeReview, scoringData, comment } = this.data;
		let { assessments } = appStore;
		let assessment = _.find(assessments, { id: activeReview.assessmentId });
		let total = 0;
		let current = 0;
		let weightedScore = 0;
		let index = 0;
		const content = (assessment &&
			Object.keys(assessment.questions).map((a) => {
				let q = assessment.questions[a];
				let { results } = activeReview;
				const formatAnswer = (answer) => {
					switch (q.type) {
						case 'multiselect': {
							return answer.map((a) => {
								return `${q.listItems[a]?.name} \n`;
							});
						}
						case 'radiogroup': {
							return q.listItems[answer]?.name;
						}
						default: {
							return answer;
						}
					}
				};
				let score = this.getScore(q, results[q.key]);
				let maxPoints = this.getQuestionPoints(q);
				total += maxPoints;
				current += score ? score : scoringData[q.key];
				weightedScore += Math.floor((score ? score / maxPoints : scoringData[q.key] / maxPoints) * Number(assessment.scoring[index]));
				index++;
				return (
					<div className="question" key={`question-answer-${a}`}>
						<div className="label">{`${Number(a) + 1}. ${q.label}`}</div>
						{q.subtext && <div className="subtext">{q.subtext}</div>}
						{q.key && results[q.key] && <div className="answer">{formatAnswer(results[q.key])}</div>}
						<div className="scoring-ctn vbox hright vcenter">
							<div className="possible-points">Possible Points: {maxPoints}</div>
							{['multiselect', 'radiogroup'].indexOf(q.type) >= 0 ? (
								<div className="score">{`Score: ${score}`}</div>
							) : (
								<div className="manual-score vbox vcenter hright">
									<div className="label">Manual Scoring:</div>
									<Stepper
										small
										min={-100}
										max={maxPoints}
										value={scoringData[q.key]}
										onStepperChange={(val) => {
											scoringData[q.key] = val;
										}}
									/>
								</div>
							)}
							<div className="weight">Weight: {assessment.scoring[index - 1]}%</div>
						</div>
					</div>
				);
			})) || <div>Unknown Error</div>;
		return (
			<div className={`results y-scroll`} key={`survey-results`}>
				<div className="total-score hbox vcenter hright">
					Total Score: <span>{`${current}/${total}`}</span>
				</div>
				<div className="weighted-score hbox vcenter hright">
					Weighted Score: <span>{`${weightedScore}/100`}</span>
				</div>
				{content}
				<BasicInput
					key={`srvy-form-input-comments`}
					validate
					label={'Review Comments (is user visible)'}
					type="textarea"
					placeholder="If you have any final comments you can put them here and the user can see this after the review is saved"
					value={comment}
					onChange={this.onCommentChange}
					className={`resizable`}
				/>
			</div>
		);
	}

	getSurveyList() {
		return (
			<div className="card-list animate__animated animate__fadeInUp">
				{appStore.surveys
					.map((survey) => {
						if (
							survey &&
							survey.status === 'reviewing' &&
							survey.target.toLowerCase().indexOf(this.data.searchQuery.toLowerCase()) >= 0
						) {
							return (
								<div
									className="list-card vbox vcenter hcenter"
									key={survey.id}
									onClick={() => {
										this.data.activeReview = survey;
										this.setScoringData();
									}}
								>
									<div className="icon-ctn">
										<i className="f7-icons">doc_checkmark_fill</i>
									</div>
									<div className="name">{survey.name}</div>
									<div className="name">{survey.target}</div>
									<div className="count">{`Questions: ${Object.keys(survey.results).length}`}</div>
								</div>
							);
						}
						return null;
					})
					.filter((i) => {
						return i !== null;
					})}
			</div>
		);
	}

	async onSaveReview() {
		let { activeReview, scoringData, comment } = this.data;
		f7.dialog.preloader();
		const completed = await API.saveReview({
			id: activeReview.id,
			scoring: scoringData,
			comment: comment,
			score: this.getWeightedScore()
		});
		if (completed) {
			this.data.activeReview = null;
			this.data.scoringData = {};
			this.data.comment = '';
			f7.dialog.close();
			this.loadData();
		}
		//save review and also leave comments that can be added to survey
		//need to send email when review is complete for them to log in and see results
		//mail chimp or something like that should be fine
	}

	render() {
		let { loading, searchQuery, activeReview } = this.data;
		return (
			<div className="review-page main-content survey-page">
				<div className="page-header hbox vcenter">
					{activeReview ? <h1>Reviewing {activeReview.name}</h1> : <h1>Surveys Awaiting Review</h1>}
					<div className="grow-1"></div>
					{activeReview && (
						<Button
							small
							className="header-btn m-l-24 "
							onClick={() => {
								this.data.activeReview = null;
								this.data.scoringData = {};
								this.data.comment = '';
							}}
						>
							Cancel
						</Button>
					)}
					<Button small className="header-btn m-l-24" onClick={this.loadData}>
						<i className="f7-icons">arrow_2_circlepath</i> Refresh
					</Button>
					{activeReview && (
						<Button small fill className="header-btn m-l-24" onClick={this.onSaveReview}>
							<i className="f7-icons">floppy_disk</i> Save
						</Button>
					)}
				</div>
				{activeReview && this.getReviewDetails()}
				{!activeReview && (
					<div className="review-list">
						{loading && (
							<div className="loading-ctn vbox vcenter hcenter">
								<Preloader color="#000" size={56}></Preloader>
							</div>
						)}
						{!loading && (
							<div className="hbox vcenter hright">
								<div className="search-ctn">
									<i className="f7-icons">search</i>
									<input
										className="search-input"
										placeholder="Search by user's name"
										value={searchQuery}
										onChange={(e) => {
											this.data.searchQuery = e.target.value;
										}}
									/>
								</div>
							</div>
						)}
						{!loading && appStore.surveys.length > 0 && this.getSurveyList()}
						{!loading && !_.find(appStore.surveys, { status: 'reviewing' }) && (
							<div className="empty-ctn">
								<h1>No surveys need reviewing at this time</h1>
								<img src={emptyPNG} className="emptyimg"></img>
							</div>
						)}
					</div>
				)}
			</div>
		);
	}
}
