import React, { Component } from 'react';
import _ from 'lodash';
import { observer, observable } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import { Button, Link, Popup } from 'framework7-react';
import './form-popup.scss';
import FormBuilder from '../form-builder/FormBuilder';

@observer
export default class FormPopup extends Component {
	constructor(props) {
		super(props);
		this.data = observable({ formValid: false });
		autoBind(this);
	}

	closeLinkPopup() {
		if (this.props.onClose) {
			this.props.onClose();
		}
	}

	handlePopupOpen() {
		console.log('Form Popup Opened');
	}

	render() {
		let {
			title,
			opened,
			buttonText,
			formData,
			formConfig,
			onSubmit,
			customValidator,
			afterChange,
			additionalContent,
			subtitle
		} = this.props;
		return (
			<Popup
				className="srvy-form-popup"
				opened={opened}
				closeByBackdropClick={false}
				closeOnEscape={false}
				onPopupClosed={this.closeLinkPopup}
				onPopupOpen={this.handlePopupOpen}
			>
				<div className="popup-header">
					<div className="title grow-1">{title}</div>
					<Link popupClose>
						<i className="f7-icons">multiply_circle</i>
					</Link>
				</div>
				<div className="main-content y-scroll">
					{!!subtitle && subtitle}
					{opened && (
						<FormBuilder
							formData={formData}
							formConfig={formConfig}
							setValidationState={(valid) => {
								if (customValidator) {
									this.data.formValid = customValidator(valid);
								} else {
									this.data.formValid = valid;
								}
							}}
							afterChange={afterChange}
						></FormBuilder>
					)}
					{additionalContent}
				</div>
				<div className="btn-ctn">
					<Button fill large className="round-btn" disabled={!this.data.formValid} onClick={onSubmit}>
						{buttonText}
					</Button>
				</div>
			</Popup>
		);
	}
}
