import _ from 'lodash';
import { f7 } from 'framework7-react';

export function autoBind(cmp, usingHOC, exclude) {
	const reactInternals = new Set([
		'constructor',
		'render',
		'componentWillMount',
		'componentDidMount',
		'componentWillReceiveProps',
		'shouldComponentUpdate',
		'componentWillUpdate',
		'componentDidUpdate',
		'componentWillUnmount'
	]);
	if (exclude && exclude.length > 0) {
		for (let i = 0; i < exclude.length; i++) {
			reactInternals.add(exclude[i]);
		}
	}
	let proto = cmp.__proto__;
	for (let key of Object.getOwnPropertyNames(proto)) {
		const { value } = Object.getOwnPropertyDescriptor(proto, key);
		// do not try to bind properties and getter/setters
		if (typeof value === 'function' && !reactInternals.has(key)) {
			cmp[key] = value.bind(cmp);
		}
	}
}

export function getUrlParams() {
	const urlParams = _.chain(window.location.href.split('?')[1])
		.replace('?', '')
		.split('&')
		.map(_.partial(_.split, _, '=', 2))
		.fromPairs()
		.value();
	delete urlParams[''];
	return urlParams;
}

export function formatPhoneNumber(phoneNumberString) {
	var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
	var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
	if (match) {
		var intlCode = match[1] ? '+1 ' : '';
		return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
	}
	return null;
}

export function validateEmail(email) {
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}

export function validateUrl(str) {
	var pattern = new RegExp(
		'^(https?:\\/\\/)?' + // protocol
			'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
			'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
			'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
			'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
			'(\\#[-a-z\\d_]*)?$',
		'i'
	); // fragment locator
	return !!pattern.test(str);
}

/**
 *
 * @param {*} f7 framework7 instance
 * @param {*} err server error object
 * @param {*} alternativeMessage if no message found on error what to say
 */
export function showError(err, alternativeMessage) {
	//close any previously opened dialot
	f7.dialog.close();
	try {
		//first try message text
		const message = err?.message;
		if (message.indexOf('/api/express') < 0) {
			f7.dialog.alert(message, 'Error:');
		} else {
			f7.dialog.alert(alternativeMessage || 'Unkwown Error Occurred Please Try Again', 'Error:');
		}
	} catch {
		//fallback text
		f7.dialog.alert(alternativeMessage || 'Unkwown Error Occurred Please Try Again', 'Error:');
	}
}

export function formatMoney(amount, decimalCount = 2, decimal = '.', thousands = ',') {
	try {
		//ensure number
		let amountNumber = Number(amount);
		decimalCount = Math.abs(decimalCount);
		decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

		const negativeSign = amountNumber < 0 ? '-' : '';

		// @ts-ignore
		let i = parseInt((amountNumber = Math.abs(Number(amountNumber) || 0).toFixed(decimalCount))).toString();
		let j = i.length > 3 ? i.length % 3 : 0;

		return (
			negativeSign +
			(j ? i.substr(0, j) + thousands : '') +
			i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
			(decimalCount
				? decimal +
				  Math.abs(amountNumber - i)
						.toFixed(decimalCount)
						.slice(2)
				: '')
		);
	} catch (e) {
		console.log(e);
	}
	return amount;
}
