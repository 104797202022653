import React from 'react';
import { Button, f7, List, ListItem, Page, Popover, Preloader, Progressbar, Range, Stepper } from 'framework7-react';
import { v4 } from 'uuid';
import { observable, observer } from '@/utils/State';
// @ts-ignore
import emptyPNG from '@/assets/empty.png';
import appStore from '@/stores/AppStore';
import API from '@/services/API';
import { autoBind, showError } from '@/utils/GeneralUtils';
import { format } from 'date-fns';
import _ from 'lodash';
import './assessment-list.scss';
import BasicInput from '@/components/basic-input/BasicInput';
import DropDown from '@/components/drop-down/DropDown';
import { de } from 'date-fns/locale';

@observer
export default class AssessmentList extends React.Component {
	constructor(props) {
		super(props);
		this.data = observable({
			loading: false,
			searchQuery: '',
			showBuilder: false,
			showScoring: false,
			canEdit: true,
			activeAssessment: { name: '', questions: [], scoring: {} }
		});
		autoBind(this);
	}

	componentDidMount() {
		this.loadAssessments();
	}

	async loadAssessments() {
		f7.dialog.preloader();
		const assessments = await API.getAssessments();
		if (assessments) {
			appStore.assessments = assessments;
			f7.dialog.close();
		}
	}

	onCreateClick() {
		this.data.canEdit = true;
		this.data.showBuilder = true;
	}

	setScoring() {
		let scoring = {};
		const baseWeight = Math.floor(100 / this.data.activeAssessment.questions.length);
		for (let i = 0; i < this.data.activeAssessment.questions.length; i++) {
			scoring[i] = baseWeight;
		}
		this.data.activeAssessment.scoring = scoring;
	}

	onToScoring() {
		this.data.showBuilder = false;
		if (!this.data.activeAssessment.id) {
			this.setScoring();
		}
		this.data.showScoring = true;
	}

	async onSaveAssessment() {
		f7.dialog.preloader();
		const assessments = await API.saveAssessment(this.data.activeAssessment);
		if (assessments) {
			this.data.showBuilder = false;
			this.data.showScoring = false;
			appStore.assessments = assessments;
			f7.dialog.close();
		}
	}

	onCloseBuilder() {
		this.data.showBuilder = false;
		this.data.canEdit = true;
	}

	onDeleteAssessment() {
		f7.dialog.confirm(
			'You cannot undo this once completed',
			'Are you sure?',

			async () => {
				f7.dialog.preloader();
				await API.deleteAssessment(this.data.activeAssessment.id);
				this.data.showBuilder = false;
				f7.dialog.close();
				this.loadAssessments();
			}
		);
	}

	onCancelBuilder() {
		if (this.data.canEdit) {
			f7.dialog.confirm(
				'Unsaved changed will be lost',
				'Are you sure?',

				() => {
					this.data.activeAssessment = { name: '', questions: [], scoring: {} };
					this.data.showBuilder = false;
				}
			);
		} else {
			this.data.activeAssessment = { name: '', questions: [], scoring: {} };
			this.data.showBuilder = false;
		}
	}

	onInputChange(evt) {
		let target = evt.currentTarget;
		let value = target.value;
		let dataProperty = _.clone(target.name);
		this.data.activeAssessment[dataProperty] = value;
	}

	onQuestionChange(evt) {
		let target = evt.currentTarget;
		let parts = target.name.split('-');
		this.data.activeAssessment.questions[parts[0]][parts[1]] = target.value;
	}

	getTypeDisplay(type) {
		switch (type) {
			case 'radiogroup': {
				return 'Multiple Choice';
			}
			case 'multiselect': {
				return 'Checkboxes';
			}
			case 'text': {
				return 'Short Answer';
			}
			case 'textarea': {
				return 'Long Answer';
			}
			case 'number': {
				return 'Number';
			}
		}
	}

	getTypeItems(qIndex) {
		let listItems = [];
		_.forEach(['radiogroup', 'multiselect', 'text', 'textarea', 'number'], (type) => {
			listItems.push(
				<ListItem
					link="#"
					popoverClose
					className="drop-down-list-item"
					key={`question-builder-dropdown-item-${type}-${qIndex}`}
					onClick={() => {
						this.onTypeChange(qIndex, type);
					}}
				>
					<div className="dd-label">{this.getTypeDisplay(type)}</div>
				</ListItem>
			);
		});
		return listItems;
	}

	buildQuestions(questions) {
		let items = [];
		for (let i = 0; i < questions.length; i++) {
			const q = questions[i];
			const qListItems = [];
			if (['radiogroup', 'multiselect'].indexOf(q.type) >= 0 && q.listItems) {
				_.forEach(Object.keys(q.listItems), (itemKey) => {
					qListItems.push(
						<div className="option hbox vcenter" key={`list-item-${q.key}-${itemKey}`}>
							<input
								className="option-input grow-1"
								value={q.listItems[itemKey].name}
								placeholder="Enter option"
								onChange={(e) => {
									try {
										_.set(this.data, `activeAssessment.questions[${i}].listItems[${itemKey}].name`, e.currentTarget.value);
									} catch (err) {
										console.log(err);
									}
								}}
							></input>
							<div className="points hbox vcenter">
								<div className="label">Points:</div>
								<Stepper
									small
									min={-100}
									max={100}
									value={q.listItems[itemKey].points}
									onStepperChange={(val) => {
										console.log(`Setting: ${itemKey} to ${val}`);
										try {
											_.set(this.data, `activeAssessment.questions[${i}].listItems[${itemKey}].points`, _.cloneDeep(val));
										} catch (err) {
											console.log(err);
										}
									}}
								/>
							</div>
							{Object.keys(q.listItems).length !== 1 && (
								<i
									className="f7-icons"
									onClick={() => {
										delete this.data.activeAssessment.questions[i].listItems[itemKey];
									}}
								>
									trash
								</i>
							)}
						</div>
					);
				});
			}
			if (q) {
				items.push(
					<div className="question-card" key={q.key}>
						<div className="hbox vtop">
							<BasicInput
								key={`question-${q.key}`}
								label={'Question'}
								name={`${i}-label`}
								type={'textarea'}
								placeholder={'Enter your question'}
								value={q.label}
								onChange={this.onQuestionChange}
								className="question-input"
							/>
							<DropDown
								key={`type-${q.key}`}
								name={`question-type-${i}`}
								label={'Answer Type'}
								className="question-type-dd"
								displayValue={this.getTypeDisplay(q.type)}
								items={this.getTypeItems(i)}
								subtext={
									<div className="subtext">
										{q.type === 'radiogroup'
											? 'Only one option can be selected at a time'
											: q.type === 'multiselect'
											? 'Multiple options can be selected at a time'
											: 'Must be manually scored'}
									</div>
								}
							></DropDown>
						</div>
						<BasicInput
							key={`subtext-${q.key}`}
							label={'Additional Details / Description'}
							name={`${i}-subtext`}
							type={'text'}
							placeholder={'(Optional)'}
							value={q.subtext}
							onChange={this.onQuestionChange}
							className="question-input"
						/>
						<div className="divider"></div>
						{['radiogroup', 'multiselect'].indexOf(q.type) >= 0 && (
							<div className="options">
								<div className="option-label">
									Answer Options: <span>(Points can be positive or negative)</span>
								</div>
								{qListItems.length > 0 && qListItems}
								<Button
									className="add-options"
									onClick={() => {
										q.listItems[v4()] = { name: '', points: 1 };
									}}
								>
									Add Option
								</Button>
							</div>
						)}
						<div className="validator"></div>
						<div className="controls hbox vcenter hright">
							{['text', 'textarea', 'number'].indexOf(q.type) >= 0 && (
								<div className="points vbox vcenter hcenter ">
									<Stepper
										small
										min={-100}
										max={100}
										value={q.points}
										onStepperChange={(val) => {
											q.points = val;
										}}
									/>
									<div className="label">Points Possible</div>
								</div>
							)}
							<div className="grow-1"></div>
							<Button small tooltip="Delete">
								<i
									className="f7-icons"
									onClick={() => {
										const index = _.findIndex(questions, { key: q.key });
										if (index > -1) {
											const qs = _.cloneDeep(this.data.activeAssessment.questions);
											qs.splice(index, 1);
											this.data.activeAssessment.questions = qs;
										}
									}}
								>
									trash
								</i>
							</Button>
							{i !== 0 && (
								<Button
									small
									tooltip="Move Up"
									onClick={() => {
										const old = _.cloneDeep(this.data.activeAssessment.questions[i - 1]);
										const toMove = _.cloneDeep(this.data.activeAssessment.questions[i]);
										this.data.activeAssessment.questions[i] = old;
										this.data.activeAssessment.questions[i - 1] = toMove;
									}}
								>
									<i className="f7-icons">arrow_up_circle</i>
								</Button>
							)}
							{questions.length - 1 !== i && (
								<Button
									small
									tooltip="Move Down"
									onClick={() => {
										const old = _.cloneDeep(this.data.activeAssessment.questions[i + 1]);
										const toMove = _.cloneDeep(this.data.activeAssessment.questions[i]);
										this.data.activeAssessment.questions[i] = old;
										this.data.activeAssessment.questions[i + 1] = toMove;
									}}
								>
									<i className="f7-icons">arrow_down_circle</i>
								</Button>
							)}
						</div>
					</div>
				);
			}
		}
		return <div className="question-container">{items}</div>;
	}

	onAddQuestion() {
		this.data.activeAssessment.questions.push({
			key: v4(),
			label: '',
			subtext: '',
			type: 'radiogroup',
			points: 1,
			listItems: { 0: { name: 'Option 1', points: 1 } }
		});
	}

	onTypeChange(questionIndex, type) {
		const existing = _.cloneDeep(this.data.activeAssessment.questions[questionIndex]);
		let question = {
			key: existing.key,
			label: existing.label,
			subtext: existing.subtext,
			type: type,
			points: existing.points
		};
		if (type === 'text' || type === 'textarea') {
			question.validator = { type: 'length', value: 1 };
			question.listItems = {};
		}
		if (type === 'number') {
			question.validator = { type: 'min', value: 1 };
			question.listItems = {};
		}
		if (type === 'number' || type === 'text' || type === 'textarea') {
			question.points = existing.points ? existing.points : 1;
		}
		if ((existing.type === 'radiogroup' || existing.type === 'multiselect') && (type === 'radiogroup' || type === 'multiselect')) {
			question.listItems = _.cloneDeep(existing.listItems);
		} else if (type === 'radiogroup' || type === 'multiselect') {
			question.listItems = { 0: { name: 'Option 1', points: 1 } };
		}
		this.data.activeAssessment.questions[questionIndex] = question;
	}

	getTotalPoints() {
		let { questions } = this.data.activeAssessment;
		let total = 0;
		_.forEach(questions, (q) => {
			total += this.getQuestionPoints(q);
		});
		return total;
	}

	getQuestionPoints(q) {
		if (['text', 'textarea', 'number'].indexOf(q.type) >= 0) {
			return q.points;
		} else if (q.type === 'multiselect') {
			let total = 0;
			_.forEach(Object.values(q.listItems), (i) => {
				if (i.points >= 0) {
					total += i.points;
				}
			});
			return total;
		} else if (q.type === 'radiogroup') {
			let max = 0;
			_.forEach(Object.values(q.listItems), (i) => {
				if (i.points >= max) {
					max = i.points;
				}
			});
			return max;
		}
	}

	onRangeChange(index, val) {
		this.data.activeAssessment.scoring[index] = val;
	}

	buildScoringList(questions) {
		let items = [];
		for (let i = 0; i < questions.length; i++) {
			const q = questions[i];
			items.push(
				<div className="question-item" key={`question-${i}`}>
					<div className="q-a-p hbox vcenter">
						<div className="question">
							<span>{i + 1}.</span>
							{q.label}
						</div>
						<div className="points">
							{`Points: `}
							<span>{this.getQuestionPoints(q)}</span>
						</div>
					</div>
					<div className="vbox vcenter hcenter">
						<div>Question Weight: {this.data.activeAssessment.scoring[i]}%</div>
						<Range
							min={0}
							max={100}
							label={true}
							step={1}
							value={this.data.activeAssessment.scoring[i]}
							scale={true}
							scaleSteps={5}
							scaleSubSteps={4}
							onRangeChanged={(val) => {
								this.onRangeChange(i, val);
							}}
						/>
					</div>
				</div>
			);
		}
		const weightTotal = _.sum(Object.values(this.data.activeAssessment.scoring));
		return (
			<div className="scoring-ctn">
				<h2 className="hbox vcenter">
					<div className={`hbox vcenter grow-1 ${weightTotal !== 100 && 'red'}`}>
						{`Weight Total: ${weightTotal}% ${weightTotal !== 100 ? '(must be equal to 100%)' : ''}`}
						<Button small onClick={this.setScoring}>
							Reset
						</Button>
					</div>
					Total Possible Points: <span>{this.getTotalPoints()}</span>
				</h2>
				{items}
			</div>
		);
	}

	getScoringDetails() {
		let { activeAssessment } = this.data;
		let { questions } = activeAssessment;
		return (
			<div className="assessment-scoring y-scroll">
				<div className="builder-header hbox vcenter hcenter">
					<div className="explainer">
						Below are your assessment questions and their total possible points. You can adjust the weights of these questions if some
						questions are valued higher than others, you can also just leave the weights equal (default).
					</div>
				</div>
				{this.buildScoringList(questions)}
			</div>
		);
	}

	cloneAssessment(assessment) {
		this.data.canEdit = true;
		const a = _.cloneDeep(assessment);
		a.name = a.name + ' Clone';
		a.id = undefined;
		this.data.activeAssessment = a;
		this.data.showBuilder = true;
	}

	getBuilder() {
		let { activeAssessment, canEdit } = this.data;
		let { name, questions } = activeAssessment;
		return (
			<div className="assessment-builder y-scroll">
				{!canEdit && (
					<div className="clone-banner vbox hcenter">
						<h3>Cannot Edit:</h3>
						<div className="vbox vcenter hcenter">
							<div>Assessments with existing surveys cannot be edited however you can clone this assessment and create another.</div>
							<Button
								small
								outline
								className="clone-btn"
								color="red"
								onClick={() => {
									this.cloneAssessment(activeAssessment);
								}}
							>
								Clone
							</Button>
						</div>
					</div>
				)}
				<div className="builder-header hbox vcenter hcenter">
					<BasicInput
						key={`assessment-name`}
						label={'Assessment Name'}
						name={'name'}
						type={'text'}
						placeholder={'Enter a name'}
						value={name}
						disabled={!canEdit}
						onChange={this.onInputChange}
						className="assessment-name-input"
					/>
					<div className="explainer">
						Assessments are the questions and scoring details used to create surveys. Once saved an assesment can be used any number of
						times to create surveys for others to take.
					</div>
				</div>
				{this.buildQuestions(questions)}
				<div className="add-question-actions vbox vcenter hcenter">
					<Button large outline onClick={this.onAddQuestion} className="add-question-btn">
						<i className="f7-icons">plus</i> Add Question
					</Button>
				</div>
			</div>
		);
	}

	getAssessmentList() {
		let { assessments } = appStore;
		return (
			<div className="card-list">
				{assessments.map((a) => {
					if (a.name.toLowerCase().indexOf(this.data.searchQuery.toLowerCase()) < 0) {
						return '';
					}
					return (
						<div
							className="list-card vbox vcenter hcenter"
							key={a.id}
							onClick={async () => {
								f7.dialog.preloader();
								this.data.canEdit = !(await API.assessmentHasSurvey(a.id));
								this.data.activeAssessment = a;
								this.data.showBuilder = true;
								f7.dialog.close();
							}}
						>
							<div className="icon-ctn">
								<i className="f7-icons">doc_checkmark_fill</i>
							</div>
							<div className="name">{a.name}</div>
							<div className="count">{`Questions: ${a.questions.length}`}</div>
						</div>
					);
				})}
			</div>
		);
	}

	render() {
		let { loading, searchQuery, showBuilder, activeAssessment, showScoring, canEdit } = this.data;
		return (
			<div className="assessments-page main-content survey-page">
				<div className="page-header hbox vcenter animate__animated animate__fadeIn">
					{!showBuilder && !showScoring && <h1>Available Assessments</h1>}
					{showBuilder && <h1>Create New Assessment</h1>}
					{showScoring && <h1>Review Scoring</h1>}
					<div className="grow-1"></div>
					{!showBuilder && !showScoring && (
						<Button small className="header-btn" onClick={this.loadAssessments}>
							<i className="f7-icons">arrow_2_circlepath</i> Refresh
						</Button>
					)}
					{!showBuilder && !showScoring && (
						<Button small fill className="header-btn m-l-24 " onClick={this.onCreateClick}>
							<i className="f7-icons">plus</i> Create
						</Button>
					)}
					{showBuilder && canEdit && activeAssessment.id && (
						<Button small className="header-btn m-l-24 " onClick={this.onDeleteAssessment}>
							Delete
						</Button>
					)}
					{showBuilder && (
						<Button small className="header-btn m-l-24 " onClick={this.onCancelBuilder}>
							Cancel
						</Button>
					)}
					{showBuilder && (
						<Button
							small
							fill
							className="header-btn m-l-24"
							disabled={_.isEmpty(activeAssessment.name) || activeAssessment.questions.length === 0}
							onClick={this.onToScoring}
						>
							Scoring <i className="f7-icons ml8">chevron_right</i>
						</Button>
					)}
					{showScoring && (
						<Button
							small
							className="header-btn m-l-24 "
							onClick={() => {
								this.data.showScoring = false;
								this.data.showBuilder = true;
							}}
						>
							Back
						</Button>
					)}
					{showScoring && canEdit && (
						<Button
							small
							fill
							className="header-btn m-l-24"
							onClick={this.onSaveAssessment}
							disabled={this.data.canEdit && _.sum(Object.values(this.data.activeAssessment.scoring)) !== 100}
						>
							<i className="f7-icons">floppy_disk</i> Save
						</Button>
					)}
				</div>
				{showScoring && this.getScoringDetails()}
				{showBuilder && this.getBuilder()}
				{!showBuilder && !showScoring && (
					<div className="assessment-list animate__animated animate__fadeInUp">
						{loading && (
							<div className="loading-ctn vbox vcenter hcenter">
								<Preloader color="#000" size={56}></Preloader>
							</div>
						)}
						{!loading && (
							<div className="hbox vcenter hright">
								<div className="search-ctn">
									<i className="f7-icons">search</i>
									<input
										className="search-input"
										placeholder="Search by assessment name"
										value={searchQuery}
										onChange={(e) => {
											this.data.searchQuery = e.target.value;
										}}
									/>
								</div>
							</div>
						)}
						{!loading && appStore.assessments.length > 0 && this.getAssessmentList()}
						{!loading && appStore.assessments.length === 0 && (
							<div className="empty-ctn">
								<h1>You have no available assessments</h1>
								<img src={emptyPNG} className="emptyimg"></img>
							</div>
						)}
					</div>
				)}
			</div>
		);
	}
}
