import { observable } from '../utils/State';
const appStore = observable({
	isLoggedIn: false,
	showReauthLoader: false,
	isCreatingUser: false,
	accessCode: '',
	activePage: 'surveys',
	activeSurvey: null,
	authToken: null,
	currentUser: null,
	activeResults: null,
	activeResultsGroup: [],
	assessments: [],
	surveys: [],
	users: [],
	invites: [],
	isAdmin: () => {
		return appStore.currentUser && appStore.currentUser.role === 'admin';
	}
});
export default appStore;
