import API from '@/services/API';
import appStore from '@/stores/AppStore';
import { Button, List, ListItem, Preloader, Progressbar } from 'framework7-react';
import React, { Component } from 'react';
import { autoBind } from '../../utils/GeneralUtils';
import { observer, observable } from '../../utils/State';
import _ from 'lodash';
import './results-page.scss';
import BasicInput from '../basic-input/BasicInput';

@observer
export default class ResultsPage extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			loading: false,
			assessment: null
		});
		autoBind(this);
	}

	async componentDidMount() {
		this.data.loading = true;
		const assessment = await API.getSurveyAssesment(appStore.activeResults?.id);
		if (assessment) {
			this.data.assessment = assessment;
		} else {
			appStore.activeResults = null;
		}
		this.data.loading = false;
	}

	getActivePage() {
		let { assessment, loading } = this.data;
		let { activeResults } = appStore;
		if (loading || !assessment || !activeResults) {
			return (
				<div className="loading-ctn vbox vcenter hcenter">
					<Preloader color="#000" size={56}></Preloader>
				</div>
			);
		}
		//====================DYNAMIC SURVEY RESULTS BUILDER =============================
		const content = (assessment &&
			Object.keys(assessment.questions).map((a) => {
				let q = assessment.questions[a];
				let { results } = activeResults;
				const formatAnswer = (answer) => {
					switch (q.type) {
						case 'multiselect': {
							return answer.map((a) => {
								return `${q.listItems[a]?.name} \n`;
							});
						}
						case 'radiogroup': {
							return q.listItems[answer]?.name;
						}
						default: {
							return answer;
						}
					}
				};
				return (
					<div className="question" key={`question-answer-${a}`}>
						<div className="label">{`${Number(a) + 1}. ${q.label}`}</div>
						{q.subtext && <div className="subtext">{q.subtext}</div>}
						{q.key && results[q.key] && <div className="answer">{formatAnswer(results[q.key])}</div>}
					</div>
				);
			})) || <div>Unknown Error</div>;
		return (
			<div className={`results y-scroll`} key={`survey-results`}>
				{content}
				{appStore.activeResults?.status === 'completed' && !_.isEmpty(appStore.activeResults?.comment) && (
					<>
						<h4>Reviewer Comment:</h4>
						<div className="comments">{appStore.activeResults.comment}</div>
					</>
				)}
			</div>
		);
	}

	render() {
		if (!appStore.activeResults) {
			return '';
		}

		return (
			<div className="results-screen-backdrop">
				<div className="active-page animate__animated animate__zoomIn">
					<div className="page-header hbox vcenter hright">
						<h1 className="grow-1">{appStore.activeResults.name} Results</h1>
						<Button
							small
							onClick={() => {
								if (this.props.onClose) {
									this.props.onClose();
								}
								appStore.activeResults = null;
							}}
						>
							Close
						</Button>
					</div>
					<div className="step-ctn">{this.getActivePage()}</div>
					<div className="btn-ctn hbox vcenter">
						<h3>
							{appStore.activeResults.status === 'reviewing'
								? 'Currently Under Review'
								: `Score: ${appStore.activeResults?.score || 0}/100`}
						</h3>
					</div>
				</div>
			</div>
		);
	}
}
