import HomePage from '../pages/home/home.jsx';
import LandingPage from '../pages/landing-page/landing.jsx';
import NotFoundPage from '../pages/404.jsx';

var routes = [
	{
		path: '/',
		component: LandingPage
	},
	{
		path: '/home',
		component: HomePage
	},
	{
		path: '(.*)',
		component: NotFoundPage
	}
];

export default routes;
