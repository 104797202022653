import React from 'react';
import { Button, f7, f7ready, List, ListInput, Page } from 'framework7-react';
import { observable, observer } from '@/utils/State';
// @ts-ignore
import spacePNG from '@/assets/space.png';
// @ts-ignore
import logoPNG from '@/assets/logo.png';
// @ts-ignore
import MBSPNG from '@/assets/mbslogo.png';
import './landing.scss';
import { autoBind, validateEmail } from '@/utils/GeneralUtils';
import appStore from '@/stores/AppStore';
import _ from 'lodash';
import AuthService from '@/services/AuthService';
import API from '@/services/API';

@observer
export default class LandingPage extends React.Component {
	constructor(props) {
		super(props);
		this.data = observable({
			step: 0,
			email: '',
			password: '',
			confirm: '',
			fullName: '',
			inviteData: null,
			createValid: false
		});
		autoBind(this);
		// @ts-ignore
		window.API = API;
	}

	componentDidMount() {
		if (appStore.accessCode !== '') {
			this.data.step = 1;
			this.loadInviteData();
		}
	}

	async loadInviteData() {
		f7ready(async () => {
			f7.dialog.preloader();
			let inviteData = await API.getInviteSignupData(appStore.accessCode);
			if (inviteData) {
				this.data.inviteData = inviteData;
				f7.dialog.close();
			}
		});
	}

	isCreateValid() {
		let { email, password, confirm } = this.data;
		if (password !== confirm) {
			return false;
		}
		var strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');
		const isStrong = strongRegex.test(password);
		if (!isStrong) {
			return false;
		}
		return validateEmail(email);
	}

	async onCreateAccount() {
		f7.dialog.preloader();
		let { email, password, fullName } = this.data;
		const create = await AuthService.createAccount(email, password, fullName, appStore.accessCode);
		if (create.success) {
			console.log('Create Success');
			this.data.step = 0;
			this.data.password = '';
			this.data.confirm = '';
			f7.dialog.close();
		} else {
			console.log('Create Failed');
			f7.dialog.close();
		}
	}

	isSignInValid() {
		let { email, password } = this.data;
		var strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');
		const isStrong = strongRegex.test(password);
		if (!isStrong) {
			return false;
		}
		return validateEmail(email);
	}

	onSignIn() {
		let { email, password } = this.data;
		AuthService.handleSignIn(email, password);
	}

	onInputChange(e) {
		this.data[e.target.name] = e.target.value;
	}

	getCurrentStep() {
		switch (this.data.step) {
			case 0: {
				return (
					<div className="form-ctn vbox vcenter hcenter">
						<h1>Sign In</h1>
						<List noHairlinesMd className="ios input-list">
							<ListInput
								label="E-mail"
								type="email"
								name="email"
								placeholder="Your e-mail"
								value={this.data.email}
								onChange={this.onInputChange}
								validate
							></ListInput>
							<ListInput
								label="Password"
								type="password"
								name="password"
								placeholder="Your password"
								value={this.data.password}
								onChange={this.onInputChange}
							></ListInput>
						</List>

						<Button large fill disabled={!this.isSignInValid()} onClick={this.onSignIn}>
							Sign In
						</Button>
						<h2>OR</h2>
						<Button
							large
							onClick={() => {
								this.data.step++;
							}}
						>
							I have a code
						</Button>
					</div>
				);
			}
			case 1: {
				return (
					<div className="form-ctn vbox vcenter hcenter">
						{!this.data.inviteData && <h1>Enter Your Access Code Below:</h1>}
						{this.data.inviteData && (
							<div className="invite-ctn">
								{this.data.inviteData.logo === 'mbs' && (
									<div className="logo-ctn vbox vcenter hcenter">
										<img src={MBSPNG} />
									</div>
								)}
								{this.data.inviteData.logo === 'mbs' && <h1>You've been given access to:</h1>}
								{this.data.inviteData.logo !== 'mbs' && <h1>{this.data.inviteData.org}</h1>}
								{this.data.inviteData.logo !== 'mbs' && <h1>Have given you access to:</h1>}

								{this.data.inviteData.surveys.map((a) => {
									return (
										<div className="survey" key={Math.random()}>
											{a}
										</div>
									);
								})}
							</div>
						)}
						<List noHairlinesMd noHairlinesIos className="ios input-list">
							<ListInput
								label="Access Code"
								type="text"
								name="accessCode"
								placeholder="Your access code"
								value={appStore.accessCode}
								onChange={(e) => {
									appStore.accessCode = e.target.value;
								}}
							></ListInput>
						</List>
						<Button
							large
							fill
							onClick={() => {
								if (!this.data.inviteData && !_.isEmpty(appStore.accessCode)) {
									this.loadInviteData();
								} else if (this.data.inviteData) {
									this.data.step = 2;
								}
							}}
						>
							{this.data.inviteData ? 'Next' : 'Submit'}
						</Button>
						<Button
							large
							onClick={() => {
								this.data.step = 0;
								this.data.inviteData = null;
							}}
						>
							Back
						</Button>
					</div>
				);
			}
			case 2: {
				return (
					<div className="form-ctn vbox vcenter hcenter">
						<h1>Create Account</h1>
						<List noHairlinesMd noHairlinesIos className="ios input-list">
							<ListInput
								label="Full Name"
								type="text"
								name="fullName"
								placeholder="First & Last Name"
								value={this.data.fullName}
								onChange={this.onInputChange}
							></ListInput>
							<ListInput
								label="E-mail"
								type="email"
								name="email"
								placeholder="Your e-mail"
								value={this.data.email}
								onChange={this.onInputChange}
								validate
							></ListInput>
							<ListInput
								label="Password"
								type="password"
								name="password"
								placeholder="Your password"
								value={this.data.password}
								onChange={this.onInputChange}
							></ListInput>
							<ListInput
								label="Confirm Password"
								type="password"
								name="confirm"
								placeholder="Confirm Your password"
								value={this.data.confirm}
								onChange={this.onInputChange}
							></ListInput>
						</List>
						<div className="pwtext">Password must contain 8 or more characters, at least 1 capital letter, and 1 number</div>
						<Button large fill disabled={!this.isCreateValid()} onClick={this.onCreateAccount}>
							Submit
						</Button>
						<Button
							large
							onClick={() => {
								this.data.step = 1;
							}}
						>
							Back
						</Button>
					</div>
				);
			}
		}
	}

	render() {
		return (
			<Page name="landing" className="landing-page">
				<div className="left vbox vcenter hcenter grow-1">
					<div className="title-ctn hbox vcenter">
						<img src={logoPNG}></img>
						<div className="vbox vcenter hleft">
							<div className="title">srvy.space</div>
							<div className="subtitle">Assessments Made Easy</div>
						</div>
					</div>
					<img className="space" src={spacePNG}></img>
					<div className="copyright">&copy; 2021 Idea-Refinery LLC</div>
				</div>
				<div className="right vbox hcenter grow-1">{this.getCurrentStep()}</div>
			</Page>
		);
	}
}
