import { Input } from 'framework7-react';
import React, { Component } from 'react';
import { autoBind } from '../../utils/GeneralUtils';
import { observer, observable } from '../../utils/State';
import './basic-input.scss';
@observer
export default class BasicInput extends Component {
	constructor(props) {
		super();
		autoBind(this);
	}

	onValidate(data) {
		if (this.props.onValidate) {
			this.props.onValidate(data);
		}
	}

	onFocus(evt) {
		if (this.props.onFocus) {
			this.props.onFocus();
		}
	}

	onBlur() {
		if (this.props.onBlur) {
			this.props.onBlur();
		}
	}

	handleIconClick(evt) {
		if (this.props.iconAction) {
			this.props.iconAction(evt);
		}
	}

	render() {
		let {
			errorMessage,
			label,
			className,
			type,
			placeholder,
			onChange,
			name,
			maxlength,
			minlength,
			min,
			max,
			validate,
			value,
			onKeyDown,
			disabled,
			id,
			readOnly,
			icon,
			autoComplete,
			autofocus
		} = this.props;
		return (
			<div className={`basic-input ${className ? className : ''}`}>
				{label && <div className="label">{label}</div>}
				{icon && (
					<div className="input-icon-holder" onClick={this.handleIconClick}>
						<i className="fal fa-calendar-alt" />
					</div>
				)}
				<Input
					className={readOnly ? 'read-only' : ''}
					type={type}
					id={id}
					placeholder={placeholder}
					onChange={onChange}
					name={name}
					maxlength={maxlength || 999}
					minlength={minlength || 0}
					min={min || 0}
					max={max || 999}
					onValidate={this.onValidate}
					readonly={readOnly}
					value={value || ''}
					disabled={disabled}
					validate={!!validate}
					onFocus={this.onFocus}
					onBlur={this.onBlur}
					autoComplete={autoComplete || 'off'}
					autofocus={!!autofocus}
					onKeyDown={(e) => {
						if (onKeyDown) {
							onKeyDown(e);
						}
					}}
				/>
				<div className="error-message">{errorMessage}</div>
			</div>
		);
	}
}
