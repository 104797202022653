import API from '@/services/API';
import appStore from '@/stores/AppStore';
import { Button, f7, List, ListItem, Preloader, Progressbar } from 'framework7-react';
import React, { Component } from 'react';
import { autoBind } from '../../utils/GeneralUtils';
import { observer, observable } from '../../utils/State';
import _ from 'lodash';
import './survey-screen.scss';
import BasicInput from '../basic-input/BasicInput';

@observer
export default class SurveyScreen extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			step: 0,
			loading: false,
			assessment: null,
			isStepValid: false,
			stepAnimation: 'animate__animated animate__fadeInUp'
		});
		autoBind(this);
	}

	async componentDidMount() {
		this.data.loading = true;
		const assessment = await API.getSurveyAssesment(appStore.activeSurvey?.id);
		if (assessment) {
			this.data.assessment = assessment;
		}
		this.setCurrentStep();
		this.runValidator();
		this.data.loading = false;
	}

	setCurrentStep() {
		const results = appStore.activeSurvey?.results;
		if (Object.keys(results).length > 0) {
			this.data.step = Object.keys(results).length;
		}
	}

	validateField(validator, value) {
		switch (validator.type) {
			case 'length': {
				if (value && value.length >= validator.value) {
					return true;
				}
				return false;
			}
			case 'min': {
				return Number(value) >= Number(validator.value) ? true : false;
			}
			case 'max': {
				return Number(value) <= Number(validator.value) ? true : false;
			}
			case 'multiselect': {
				return value && value.length > 0 ? true : false;
			}
			case 'radiogroup': {
				return !!value;
			}
		}
	}

	runValidator() {
		let { assessment, step } = this.data;
		let { activeSurvey } = appStore;
		let { results } = activeSurvey;
		let isStepValid = true;
		if (step === 0) {
			this.data.isStepValid = true;
			return;
		}
		let question = assessment.questions[_.clone(step) - 1];
		if (!question) {
			this.data.isStepValid = false;
			return;
		}
		let validator = question?.validator;
		if (validator) {
			let valid = this.validateField(validator, results[question.key]);
			if (!valid) {
				isStepValid = false;
			}
		}
		if (question.type === 'multiselect') {
			let valid = this.validateField({ type: 'multiselect' }, results[question.key]);
			if (!valid) {
				isStepValid = false;
			}
		}
		if (question.type === 'radiogroup') {
			let valid = this.validateField({ type: 'radiogroup' }, results[question.key]);
			if (!valid) {
				isStepValid = false;
			}
		}
		this.data.isStepValid = isStepValid;
	}

	onInputChange(evt) {
		let target = evt.currentTarget;
		let value = target.value;
		appStore.activeSurvey.results[target.name] = value;
		this.runValidator();
	}

	buildQuestion(question) {
		let { activeSurvey } = appStore;
		let { results } = activeSurvey;
		let questionInput = <div></div>;
		let { key, type, placeholder, label, listItems, subtext } = question;
		switch (question.type) {
			case 'text': {
				questionInput = (
					<BasicInput
						key={`srvy-form-input-${key}`}
						name={key}
						type={type}
						placeholder={placeholder || ''}
						value={results[key] || ''}
						onChange={this.onInputChange}
					/>
				);
				break;
			}
			case 'number': {
				questionInput = (
					<BasicInput
						key={`srvy-form-input-${key}`}
						name={key}
						type={type}
						placeholder={placeholder || ''}
						value={results[key] || ''}
						onChange={this.onInputChange}
					/>
				);
				break;
			}
			case 'textarea': {
				questionInput = (
					<BasicInput
						key={`srvy-form-textarea-${key}`}
						name={key}
						type={type}
						placeholder={placeholder || ''}
						value={results[key] || ''}
						className="textarea-input"
						onChange={this.onInputChange}
					/>
				);
				break;
			}
			case 'multiselect': {
				questionInput = (
					<div className={`multiselect-form-selector`} key={key}>
						<List noHairlines>
							{Object.keys(listItems).map((k) => {
								let item = listItems[k];
								return (
									<ListItem
										checkbox
										title={item.name}
										name={`${key}-checkbox`}
										key={k}
										value={k}
										checked={appStore.activeSurvey.results[key]?.indexOf(k) >= 0}
										onChange={() => {
											let { results } = appStore.activeSurvey;
											let arr = _.clone(results[key]);
											if (!arr) {
												arr = [];
											}
											if (arr.indexOf(k) < 0) {
												arr.push(k);
											} else {
												const index = arr.indexOf(k);
												if (index > -1) {
													arr.splice(index, 1);
												}
											}
											appStore.activeSurvey.results[key] = arr;
											this.runValidator();
										}}
									/>
								);
							})}
						</List>
					</div>
				);
				break;
			}
			case 'radiogroup': {
				questionInput = (
					<div className={`radiogroup-form-selector`} key={key}>
						<List noHairlines>
							{Object.keys(listItems).map((k) => {
								return (
									<ListItem
										radio
										title={listItems[k].name}
										name={`${key}-radio`}
										key={k}
										value={k}
										checked={appStore.activeSurvey.results[key] === k}
										onChange={() => {
											appStore.activeSurvey.results[key] = k;
											this.runValidator();
										}}
									/>
								);
							})}
						</List>
					</div>
				);
				break;
			}
		}
		return (
			<div className="question">
				<h1>{label}</h1>
				{subtext && <p>{subtext}</p>}
				{questionInput}
			</div>
		);
	}

	getActivePage() {
		let { assessment, loading, stepAnimation, step } = this.data;
		let { activeSurvey } = appStore;
		if (loading || !assessment || !activeSurvey) {
			return (
				<div className="loading-ctn vbox vcenter hcenter">
					<Preloader color="#000" size={56}></Preloader>
				</div>
			);
		}
		//====================DYNAMIC SURVEY FORM BUILDER =============================
		let content;
		if (step === 0) {
			content = (
				<div className="start vbox vcenter hcenter">
					<h3>You've been invited to take:</h3>
					<h1>{assessment.name}</h1>
					<h3>There are {appStore.activeSurvey?.progress.total} questions</h3>
				</div>
			);
		} else {
			let { questions } = assessment;
			let question = questions[Number(step) - 1];
			content = this.buildQuestion(question);
		}
		return (
			<div className={`active-step y-scroll ${stepAnimation}`} key={`active-step-${step}`}>
				{content}
			</div>
		);
	}

	render() {
		if (!appStore.activeSurvey) {
			return '';
		}
		let { step } = this.data;
		return (
			<div className="survey-screen-backdrop">
				<div className="active-page animate__animated animate__zoomIn">
					<div className="page-header hbox vcenter hright">
						<Progressbar
							progress={Math.floor((Number(step) / Number(appStore.activeSurvey?.progress?.total)) * 100)}
							className="survey-progress"
						/>
						<Button
							small
							onClick={() => {
								if (this.props.onClose) {
									this.props.onClose();
								}
								appStore.activeSurvey = null;
							}}
						>
							Close
						</Button>
					</div>
					<div className="step-ctn">{this.getActivePage()}</div>
					<div className="btn-ctn hbox vcenter">
						{step !== 0 && (
							<Button
								large
								onClick={() => {
									this.data.step--;
									this.runValidator();
									this.data.stepAnimation = 'animate__animated animate__fadeInDown';
								}}
							>
								Back
							</Button>
						)}
						<Button
							fill
							large
							disabled={!this.data.isStepValid}
							onClick={async () => {
								if (appStore.activeSurvey?.progress.total > this.data.step) {
									this.data.step++;
									API.updateSurveyResults(appStore.activeSurvey);
								} else {
									f7.dialog.preloader();
									await API.updateSurveyResults(appStore.activeSurvey);
									await API.completeSurvey(appStore.activeSurvey);
									if (this.props.onClose) {
										this.props.onClose();
									}
									appStore.activeSurvey = null;
									f7.dialog.close();
									return;
								}
								this.runValidator();
								this.data.stepAnimation = 'animate__animated animate__fadeInUp';
							}}
						>
							{step === appStore.activeSurvey?.progress?.total ? 'Complete' : step === 0 ? 'Start' : 'Next'}
						</Button>
					</div>
				</div>
			</div>
		);
	}
}
